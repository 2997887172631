<template>
  <div class="af-modal-card" :style='{"width":"500px"}'>
    <template v-if="!submitted">
      <form @submit.prevent="submit">
        <validation-observer ref="observer">
          <p class="has-text-weight-bold is-size-4">
            {{ $t("af:psd2_field.mistake_found_modal.title") }}
          </p>

          <p class="mb-5 mt-4">
            {{ $t("af:psd2_field.mistake_found_modal.description") }}
          </p>

          <BInputWithValidation
            :label="$t('af:preliminary.overview.input.income_amount.label')"
            :mask="FIELD_MASK.HUF"
            :rules="{ regex: /^[0-9 ]*$/ }"
            label-position="on-border"
            v-model="overrideSalary"
          />
          <BInputWithValidation
            :label="$t('af:preliminary.overview.input.loan_amount.label')"
            :mask="FIELD_MASK.HUF"
            :rules="{ regex: /^[0-9 ]*$/ }"
            label-position="on-border"
            v-model="overrideLoans"
          />
          <br />
          <BInputWithValidation
            v-model="message"
            rules="required"
            :label="$t('af:psd2_field.mistake_found_modal.input.comment.label')"
            label-position="on-border"
            type="textarea"
            class="mistake-found-textarea"
          />

          <div class="is-flex mt-5">
            <b-button
              type="is-blue"
              :value="$t('af:psd2_field.mistake_found_modal.button.submit')"
              tag="input"
              class="mb-2"
              @click="submit"
            />

            <b-button
              type="is-text"
              :value="$t('af:psd2_field.mistake_found_modal.button.cancel')"
              tag="input"
              class="psd2-skip-button ml-3"
              @click="cancelModal"
            />
          </div>
        </validation-observer>
      </form>
    </template>

    <template v-else>
      <p class="has-text-weight-bold is-size-4">
        {{ $t("af:psd2_field.mistake_found_modal.submitted.title") }}
      </p>

      <p class="mb-5 mt-4">
        {{ $t("af:psd2_field.mistake_found_modal.submitted.description") }}
      </p>

      <div class="is-flex mt-5 has-text-centered">
        <b-button
          type="is-blue"
          :value="
            $t('af:psd2_field.mistake_found_modal.submitted.button.submit')
          "
          tag="input"
          class="mb-2"
          @click="cancelModal"
        />
      </div>
    </template>

    <div class="close-btn cursor-pointer" @click="cancelModal">
      <b-icon icon-pack="fas" icon="times" type="is-grey-icon" />
    </div>
  </div>
</template>

<script>
import BInputWithValidation from "../BInputWithValidation";
import i18n from "../../plugins/i18n";
import { FIELD_MASK } from "@/utils/const";

export default {
  name: "Psd2MistakeFoundModal",
  components: {
    BInputWithValidation,
  },
  data() {
    return {
      FIELD_MASK,
      message: null,
      overrideLoans: 0,
      overrideSalary: 0,
      submitted: false,
    };
  },
  methods: {
    async mounted() {},
    cancelModal() {
      this.$parent.close();
    },
    async submit() {
      let valid = await this.$refs.observer.validate();
      if (!valid) {
        return;
      }
      try {
        await this.$store.dispatch("psd2Sync/mistakeFound", {
          message: this.message,
          overrideSalary: this.overrideSalary,
          overrideLoans: this.overrideLoans,
        });
        this.submitted = true;
      } catch (e) {
        this.$buefy.toast.open({
          message: i18n.t("af:psd2_field.mistake_found_modal.submitted.failed"),
          type: "is-danger",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.mistake-found-textarea {
  textarea {
    min-height: 5rem !important;
  }
}
</style>
