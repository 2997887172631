<template>
  <div>
    <template v-if="loaded">
      <div class="psd2-field">
        <template v-if="currentState === STATE.SYNC_REQUIRED">
          <p class="has-text-weight-bold is-size-5 mb-2">
            {{ label }}
          </p>

          <p class="has-text-grey is-size-6 mb-5">
            {{ description }}
          </p>

          <p>
            <slot></slot>
          </p>

          <template v-if="needsToSelectBank">
            <div class="mb-5 mt-5">
              <span class="has-text-weight-bold">{{
                $t("af:psd2_field.needs_bank_select.title")
              }}</span>
            </div>

            <BSelectWithValidation
              rules="required"
              :label="$t('af:psd2_field.needs_bank_select.input.banks.title')"
              v-model="selectedIcs"
              label-position="on-border"
            >
              <option v-for="isc in iscs" :value="isc.id" :key="isc.id">
                {{ isc.bankName }}
              </option>
            </BSelectWithValidation>
          </template>

          <div
            class="is-flex mt-6 has-text-centered is-flex-direction-column psd2-button-container"
          >
            <template v-if="needsToSelectBank">
              <b-button
                type="is-blue"
                class="mb-2"
                @click="onBankSelect"
                :loading="syncStarted"
              >
                {{ $t("af:psd2_field.needs_bank_select.button.bank_select") }}
              </b-button>
            </template>

            <template v-else>
              <b-button
                type="is-blue"
                class="mb-2"
                @click="startSync(false)"
                :loading="syncStarted"
              >
                {{ $t("af:psd2_field.required.button.sync") }}
              </b-button>

              <b-button
                v-if="skippable"
                type="is-text"
                :value="$t('af:psd2_field.required.button.skip')"
                tag="input"
                class="psd2-skip-button"
                @click="skipPsd2"
              />
            </template>
          </div>
        </template>

        <template v-if="currentState === STATE.SYNC_SUCCESS">
          <p class="has-text-weight-bold is-size-5 mb-2">
            {{ label }}
          </p>

          <div class="is-flex mt-5">
            <b-icon
              icon-pack="fa"
              icon="check-circle"
              class="is-green-icon ml-2"
              size="is-huge"
            />

            <div class="is-flex is-flex-direction-column ml-4">
              <p class="has-text-weight-bold is-size-4 mb-4">
                {{ $t("af:psd2_field.success.title") }}
              </p>

              <p class="has-text-grey mt-0">
                {{ $t("af:psd2_field.success.description") }}
              </p>
            </div>
          </div>
        </template>

        <template v-if="currentState === STATE.SYNC_FAILED">
          <p class="has-text-weight-bold is-size-5 mb-2">
            {{ label }}
          </p>

          <div class="is-flex mt-5">
            <b-icon
              icon-pack="fa"
              icon="times-circle"
              class="is-red-icon ml-2"
              size="is-huge"
            />

            <div class="is-flex is-flex-direction-column ml-4">
              <p class="has-text-weight-bold is-size-4 mb-4">
                {{ $t("af:psd2_field.failed.title") }}
              </p>

              <p class="has-text-grey mt-0">
                {{ $t("af:psd2_field.failed.description") }}
              </p>
            </div>
          </div>

          <div
            class="is-flex mt-6 has-text-centered is-flex-direction-column psd2-button-container"
          >
            <b-button
              type="is-blue"
              :value="$t('af:psd2_field.failed.button.sync')"
              tag="input"
              class="mb-2"
              @click="startSync(false)"
            />

            <b-button
              v-if="skippable"
              type="is-text"
              :value="$t('af:psd2_field.failed.button.skip')"
              tag="input"
              class="psd2-skip-button"
              @click="skipPsd2"
            />
          </div>
        </template>

        <template v-if="currentState === STATE.SYNC_IN_PROGRESS">
          <p class="has-text-weight-bold is-size-5 mb-2">
            {{ label }}
          </p>

          <div class="is-flex mt-5">
            <LoadingSkeleton />

            <div class="is-flex is-flex-direction-column ml-4">
              <p class="has-text-weight-bold is-size-4 mb-4">
                {{ $t("af:psd2_field.in_progress.title") }}
              </p>

              <p class="has-text-grey mt-0">
                {{ $t("af:psd2_field.in_progress.description") }}
              </p>
            </div>
          </div>

          <div
            class="is-flex mt-6 has-text-centered is-flex-direction-column psd2-button-container"
          >
            <b-button
              type="is-blue"
              :value="$t('af:psd2_field.button.refresh')"
              tag="input"
              class="mb-2"
              @click="getStatus('yes')"
            />
          </div>
        </template>

        <template v-if="currentState === STATE.SYNC_COMPLETED">
          <p class="has-text-weight-bold is-size-5 mb-2">
            {{ $t("af:psd2_field.completed_in_prelim.title") }}
          </p>

          <p class="has-text-grey is-size-6 mb-5">
            {{ $t("af:psd2_field.completed_in_prelim.description") }}
          </p>

          <div class="is-flex">
            <div class="psd2-field-icon psd2-field-icon-green mr-2">
              <b-icon
                icon-pack="fa"
                class="is-green-icon"
                icon="money-bill-wave"
              ></b-icon>
            </div>

            <div class="is-flex is-flex-direction-column ml-2">
              <p class="has-text-weight-bold mb-0">
                {{ $t("af:psd2_field.completed_in_prelim.net_income.title") }}
              </p>

              <p class="has-text-grey mt-0">
                {{
                  $t("af:psd2_field.completed_in_prelim.net_income.description")
                }}
              </p>
            </div>

            <p class="has-text-weight-bold is-size-5 ml-auto mt-auto mb-auto">
              {{ data.salary | monify }} Ft
            </p>
          </div>

          <div class="is-flex mt-5">
            <div class="psd2-field-icon psd2-field-icon-orange mr-2">
              <b-icon
                icon-pack="fa"
                class="is-orange-icon"
                icon="money-bill-wave"
              ></b-icon>
            </div>

            <div class="is-flex is-flex-direction-column ml-2">
              <p class="has-text-weight-bold mb-0">
                {{ $t("af:psd2_field.completed_in_prelim.loan_amount.title") }}
              </p>

              <p class="has-text-grey mt-0">
                {{
                  $t(
                    "af:psd2_field.completed_in_prelim.loan_amount.description"
                  )
                }}
              </p>
            </div>

            <p class="has-text-weight-bold is-size-5 ml-auto mt-auto mb-auto">
              {{ data.loanAmount | monify }} Ft
            </p>
          </div>

          <template v-if="!readonly">
            <div class="is-flex mt-5 new-account-container mb-6">
              <div class="is-flex is-flex-direction-column ml-2">
                <p class="has-text-weight-bold is-size-5 mb-0">
                  {{
                    $t("af:psd2_field.completed_in_prelim.new_account.title")
                  }}
                </p>

                <p class="has-text-grey mt-0">
                  {{
                    $t(
                      "af:psd2_field.completed_in_prelim.new_account.description"
                    )
                  }}
                </p>
              </div>

              <b-button
                class="ml-2"
                type="is-blue"
                :value="
                  $t('af:psd2_field.completed_in_prelim.button.new_account')
                "
                @click="startSync(true)"
                :loading="syncStarted"
              >
                {{ $t("af:psd2_field.completed_in_prelim.button.new_account") }}
              </b-button>
            </div>

            <div class="has-text-weight-bold mt-5">
              <span>{{
                $t("af:psd2_field.completed_in_prelim.error_found.title")
              }}</span>

              <span
                type="is-text"
                class="psd2-skip-button is-clickable"
                @click="mistakeFound"
              >
                {{
                  $t("af:psd2_field.completed_in_prelim.button.mistake_found")
                }}
              </span>
            </div>

            <div class="has-text-grey mt-5">
              {{ $t("af:psd2_field.completed_in_prelim.footer") }}
            </div>
          </template>
        </template>
      </div>
    </template>

    <LoadingSkeleton v-else />
  </div>
</template>

<script>
import ApplicationFlowPsd2SkipModal from "./Psd2SkipModal";
import ApplicationFlowPsd2MistakeFoundModal from "./Psd2MistakeFoundModal";
import { mapGetters } from "vuex";
import LoadingSkeleton from "../LoadingSkeleton";
import {
  FIELD_MASK,
  PSD2_FIELD_SOURCE,
  PSD2_SYNC_OUTCOME,
  UI_CONFIGS,
} from "@/utils/const";
import i18n from "../../plugins/i18n";
import "@/scripts/aggreg8-sdk-1.0.0.js";
import BSelectWithValidation from "../BSelectWithValidation";

const STATE = {
  SYNC_REQUIRED: "SYNC_REQUIRED",
  SYNC_COMPLETED: "SYNC_COMPLETED",
  SYNC_SUCCESS: "SYNC_SUCCESS",
  SYNC_IN_PROGRESS: "SYNC_IN_PROGRESS",
  SYNC_FAILED: "SYNC_FAILED",
};

export default {
  name: "Psd2Field",
  components: { BSelectWithValidation, LoadingSkeleton },
  props: {
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    skippable: {
      type: Boolean,
      required: true,
    },
    source: {
      type: String,
      required: true,
    },
    fieldId: {
      type: [Number, String],
      required: false,
    },
    flowId: {
      type: [Number, String],
      required: false,
    },
    loanApplicationId: {
      type: [Number, String],
      required: false,
    },
    correctionId: {
      type: [Number, String],
      required: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  async created() {
    this.loaded = false;
    await this.getStatus();

    let latest = this.$store.getters["psd2/status"];
    if (
      (latest != null && latest.lastSyncStatus === "IN_PROGRESS") ||
      latest.lastSyncStatus === "LITERALLY_IN_PROGRESS"
    ) {
      this.startPolling();
    }

    this.loaded = true;
  },
  async beforeDestroy() {},
  data() {
    return {
      FIELD_MASK,
      STATE,
      currentState: STATE.SYNC_REQUIRED,
      failed: false,
      loaded: false,
      data: {},
      syncStarted: false,
      psd2SyncContinueEntityId: null,
      customerSyncToken: null,
      needsToSelectBank: false,
      skipped: false,
      iscs: [],
      selectedIcs: {},
      poll: null,
    };
  },
  computed: {
    ...mapGetters({
      syncStatus: "psd2/status",
      autoloadConfigValue: "config/getAutoloadConfigValue",
    }),
    requestParams() {
      return {
        source: this.source,
        fieldId: this.fieldId,
        correctionId: this.correctionId,
        loanApplicationId: this.loanApplicationId,
        redirectUrl: new URL(location.href),
        addBank: false,
      };
    },
    syncUiUrl() {
      return this.autoloadConfigValue(UI_CONFIGS.PSD2_SYNC_UI_URL);
    },
    bankApiUrl() {
      return this.autoloadConfigValue(UI_CONFIGS.PSD2_BANK_API_URL);
    },
  },
  methods: {
    async getStatus(force) {
      await this.$store.dispatch("psd2/getStatus", {
        force,
        flowId: this.flowId,
      });
      let latest = this.$store.getters["psd2/status"];
      if (latest != null && latest.hasSyncedBefore) {
        this.data = latest.lastSyncData;
        switch (latest.lastSyncStatus) {
          case "SUCCESS":
            if (this.poll) {
              clearInterval(this.poll);
            }
            this.currentState = STATE.SYNC_COMPLETED;
            break;
          case "FAILED":
          case "FRAUD":
            if (this.poll) {
              clearInterval(this.poll);
            }
            this.currentState = STATE.SYNC_FAILED;
            break;
          case "IN_PROGRESS":
          case "LITERALLY_IN_PROGRESS":
            this.currentState = STATE.SYNC_IN_PROGRESS;
            break;
        }
        if (this.source === PSD2_FIELD_SOURCE.PRELIM) {
          await this.$store.dispatch("preliminaryAssessment/addData", {
            question: "income",
            answer: String(latest.lastSyncData.salary),
          });
        }
      } else {
        this.currentState = STATE.SYNC_REQUIRED;
      }
    },
    startPolling() {
      this.poll = window.setInterval(() => {
        this.getStatus(false);
      }, 3000);
    },
    skipPsd2() {
      this.$buefy.modal.open({
        parent: this,
        component: ApplicationFlowPsd2SkipModal,
        hasModalCard: true,
        trapFocus: true,
        canCancel: false,
        events: {
          start: async () => await this.startSync(false),
          skip: () => {
            this.$emit("skip");
            this.skipped = true;
          },
        },
      });
    },
    mistakeFound() {
      this.$buefy.modal.open({
        parent: this,
        component: ApplicationFlowPsd2MistakeFoundModal,
        hasModalCard: true,
        trapFocus: true,
        canCancel: false,
      });
    },
    setSyncFailed() {
      console.warn("Setting Sync -> Failed");
      this.$buefy.toast.open({
        message: i18n.t("af:psd2_field.message.sync_failed"),
        type: "is-danger",
      });
      this.currentState = STATE.SYNC_FAILED;
      this.$store.dispatch("psd2/setFailed", this.psd2SyncContinueEntityId);
    },

    async startSync(addBank) {
      try {
        if (addBank) {
          this.requestParams.addBank = true;
        }

        if (this.source === PSD2_FIELD_SOURCE.PRELIM) {
          this.requestParams.redirectUrl = null;
        }

        this.syncStarted = true;
        let syncStartResponse = await this.$store.dispatch(
          "psd2/start",
          this.requestParams
        );

        this.currentState = STATE.SYNC_IN_PROGRESS;
        const syncUiUrl = syncStartResponse.userFlowUrl;
        if (this.source === PSD2_FIELD_SOURCE.PRELIM) {
          window.open(syncUiUrl, "_blank", "noreferrer");
        } else {
          window.location.replace(syncUiUrl);
        }
      } catch (e) {
        console.warn("StartSync error!");
        console.log(e);
        this.setSyncFailed();
      } finally {
        this.syncStarted = false;
      }
    },
    async continueSync(isForcedClose) {
      try {
        this.syncStarted = true;
        this.currentState = STATE.SYNC_IN_PROGRESS;

        if (isForcedClose) {
          this.setSyncFailed();
        } else {
          let syncContinueResponse = await this.$store.dispatch(
            "psd2/continue",
            this.psd2SyncContinueEntityId
          );

          if (syncContinueResponse.outcome === PSD2_SYNC_OUTCOME.SUCCESS) {
            await this.getStatus();
            this.$emit("synced");
          } else if (
            syncContinueResponse.outcome === PSD2_SYNC_OUTCOME.FAILED
          ) {
            this.currentState = STATE.SYNC_FAILED;
          }
        }
      } catch (e) {
        console.warn("ContinueSync error!");
        console.warn(e);
        this.setSyncFailed();
      } finally {
        this.syncStarted = false;
      }
    },
    isSynced() {
      return this.data.salary;
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 769px) {
  .new-account-container {
    flex-direction: column;

    .button {
      margin-top: 1rem;
    }
  }
}
</style>
