<template>
  <div class="af-modal-card" :style='{"width":"550px"}'>
    <form @submit.prevent="start">
      <p class="has-text-weight-bold is-size-4">
        {{ $t("af:psd2_field.skip_modal.title") }}
      </p>

      <p class="mb-5 mt-4">
        {{ $t("af:psd2_field.skip_modal.description") }}
      </p>

      <p>{{ $t("af:psd2_field.skip_modal.description.important.title") }}</p>
      <p class="list-item">
        {{ $t("af:psd2_field.skip_modal.description.important.row_one") }}
      </p>
      <p class="list-item">
        {{ $t("af:psd2_field.skip_modal.description.important.row_two") }}
      </p>

      <div class="is-flex mt-6 is-flex-direction-column">
        <b-button
          type="is-blue"
          :value="$t('af:psd2_field.skip_modal.sync.button')"
          tag="input"
          class="mb-2"
          @click="start"
        />

        <b-button
          type="is-text"
          :value="$t('af:psd2_field.skip_modal.skip.button')"
          tag="input"
          class="psd2-skip-button"
          @click="skip"
        />
      </div>

      <div class="close-btn cursor-pointer" @click="cancelModal">
        <b-icon icon-pack="fas" icon="times" type="is-grey-icon" />
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "Psd2SkipModal",
  methods: {
    cancelModal() {
      this.$parent.close();
    },
    skip() {
      this.$emit("skip");
      this.cancelModal();
    },
    start() {
      this.$emit("start");
      this.cancelModal();
    },
  },
};
</script>

<style scoped lang="scss">
.list-item {
  display: list-item;
  margin-left: 2em;
}
</style>
